@font-face{
    font-family: poppins;
    src: url('../fonts/Poppins/Poppins-Regular.ttf');
    font-display: swap;  
}

@font-face{
    font-family: poppins;
    src: url('../fonts/Poppins/Poppins-Bold.ttf');
    font-weight: bold;
    font-display: swap;  
}

@font-face{
    font-family: poppins;
    src: url('../fonts/Poppins/Poppins-Medium.ttf');
    font-weight: 500;
    font-display: swap;  
}

@font-face{
    font-family: poppins;
    src: url('../fonts/Poppins/Poppins-SemiBold.ttf');
    font-weight: 600;
    font-display: swap;
}

@font-face{
    font-family: poppins;
    src: url('../fonts/Poppins/Poppins-Light.ttf');
    font-weight: 300;
    font-display: swap;
}

@font-face{
    font-family: poppins;
    src: url('../fonts/Poppins/Poppins-Bold.ttf');
    font-weight: 700;
    font-display: swap;
}


@font-face{
    font-family: poppins;
    src: url('../fonts/Poppins/Poppins-ExtraBold.ttf');
    font-weight: 900;
    font-display: swap;
}

@font-face{
    font-family: roboto;
    src: url('../fonts/Roboto/Roboto-Regular.ttf');
    font-display: swap;  
}

@font-face{
    font-family: roboto;
    src: url('../fonts/Roboto/Roboto-Bold.ttf');
    font-weight: bold;
    font-display: swap;  
}

@font-face{
    font-family: roboto;
    src: url('../fonts/Roboto/Roboto-Medium.ttf');
    font-weight: 500;
    font-display: swap;  
}

/* @font-face{
    font-family: roboto;
    src: url('../fonts/Roboto/Roboto-SemiBold.ttf');
    font-weight: 600;
    font-display: swap;
} */

@font-face{
    font-family: roboto;
    src: url('../fonts/Roboto/Roboto-Light.ttf');
    font-weight: 300;
    font-display: swap;
}

@font-face{
    font-family: roboto;
    src: url('../fonts/Roboto/Roboto-Bold.ttf');
    font-weight: 700;
    font-display: swap;
}

@font-face{
    font-family: roboto-condensed;
    src: url('../fonts/Roboto-Condensed/RobotoCondensed-Regular.ttf');
    font-weight: 500;
    font-display: swap;
}


/* @font-face{
    font-family: roboto;
    src: url('../fonts/Roboto/Roboto-ExtraBold.ttf');
    font-weight: 900;
    font-display: swap;
} */

@font-face{
    font-family: oswald;
    src: url('../fonts/Oswald/Oswald-Regular.ttf');
    font-display: swap;  
}

@font-face{
    font-family: oswald;
    src: url('../fonts/Oswald/Oswald-Bold.ttf');
    font-weight: 900;
    font-display: swap;  
}

@font-face{
    font-family: oswald;
    src: url('../fonts/Oswald/Oswald-Medium.ttf');
    font-weight: 500;
    font-display: swap;  
}

@font-face{
    font-family: oswald;
    src: url('../fonts/Oswald/Oswald-SemiBold.ttf');
    font-weight: 600;
    font-display: swap;
}

@font-face{
    font-family: oswald;
    src: url('../fonts/Oswald/Oswald-Light.ttf');
    font-weight: 300;
    font-display: swap;
}

@font-face{
    font-family: oswald;
    src: url('../fonts/Oswald/Oswald-Bold.ttf');
    font-weight: 700;
    font-display: swap;
}


/* @font-face{
    font-family: oswald;
    src: url('../fonts/Oswald/Oswald-ExtraBold.ttf');
    font-weight: 900;
    font-display: swap;
} */

 
@font-face {
    font-family: OpenSauceOne;
    src: url('../fonts/OpenSauceOne/OpenSauceOne-Regular.ttf');
    font-display: swap;
}

@font-face {
    font-family: OpenSauceOne;
    src: url('../fonts/OpenSauceOne/OpenSauceOne-Bold.ttf');
    font-weight: bold;
    font-display: swap;
}

@font-face {
    font-family: OpenSauceOne;
    src: url('../fonts/OpenSauceOne/OpenSauceOne-ExtraBold.ttf');
    font-weight: 900;
    font-display: swap;
}

@font-face {
    font-family: OpenSauceOne;
    src: url('../fonts/OpenSauceOne/OpenSauceOne-Light.ttf');
    font-weight: 300;
    font-display: swap;
}

@font-face {
    font-family: OpenSauceOne;
    src: url('../fonts/OpenSauceOne/OpenSauceOne-Medium.ttf');
    font-weight: 500;
    font-display: swap;
}

@font-face {
    font-family: OpenSauceOne;
    src: url('../fonts/OpenSauceOne/OpenSauceOne-SemiBold.ttf');
    font-weight: 600;
    font-display: swap;
}

@font-face {
    font-family: OpenSauceOne;
    src: url('../fonts/OpenSauceOne/OpenSauceOne-Black.ttf');
    font-weight: 900;
    font-display: swap;
}

@font-face {
    font-family: Raleway;
    src: url('../fonts/Releway/Raleway-Bold.ttf');
    font-weight: 700;
}

@font-face {
    font-family: Raleway;
    src: url('../fonts/Releway/Raleway-ExtraBold.ttf');
    font-weight: 800;
}

@font-face {
    font-family: Raleway;
    src: url('../fonts/Releway/Raleway-Light.ttf');
    font-weight: 400;
}

@font-face {
    font-family: Raleway;
    src: url('../fonts/Releway/Raleway-Medium.ttf');
    font-weight: 600;
}
@font-face {
    font-family: Raleway;
    src: url('../fonts/Releway/Raleway-Regular.ttf');
    font-weight: 500;
}

/*  */

@font-face {
    font-family: Montserrat;
    src: url('../fonts/Montserrat/Montserrat-Bold.ttf');
    font-weight: 700;
}

@font-face {
    font-family: Montserrat;
    src: url('../fonts/Montserrat/Montserrat-ExtraBold.ttf');
    font-weight: 800;
}

@font-face {
    font-family: Montserrat;
    src: url('../fonts/Montserrat/Montserrat-Light.ttf');
    font-weight: 400;
}

@font-face {
    font-family: Montserrat;
    src: url('../fonts/Montserrat/Montserrat-Medium.ttf');
    font-weight: 600;
}
@font-face {
    font-family: Montserrat;
    src: url('../fonts/Montserrat/Montserrat-Regular.ttf');
    font-weight: 500;
}


:root{
    --primary-ss-color: #FF379F; /* #1C4C9A */
    --secondary-ss-color: #64CBCC;
    --primary-ssl-color: #f2f8fc;
    --secondary-ssl-color: #e8e9e8;
    --black-color: #000000;

}

*{
    padding: 0;
    margin: 0;
}

body{
    font-family: OpenSauceOne, sans-serif;
}



.text-open-sauce-one{
    font-family: OpenSauceOne, sans-serif;
}

.text-poppins{
    font-family: Poppins, sans-serif;
}

.text-roboto{
    font-family: Roboto, sans-serif;
}

.text-oswald{
    font-family: Oswald, sans-serif;
}

.text-raleway{
    font-family: Raleway, sans-serif;
}

.text-montserrat{
    font-family: Montserrat, sans-serif;
}

.text-roboto-con{
    font-family: Roboto-Condensed, sans-serif;
}

.header-logo{
    color: #00477f;
}

.header-logo:hover{
    color: var(--primary-ss-color)
}

.text-ss-primary{
    color: var(--primary-ss-color) !important;
}

.btn-hover:hover{
    transform: scale(1.1) !important;
    /* transition-delay/: 2s; */
}

.text-ss-secondary{
    color: var(--secondary-ss-color);
}

.text-ss-black{
    color: var(--black-color);
}

.bg-ss-primary{
    background-color: var(--primary-ss-color);
}
.bg-ss-secondary{
    background-color: var(--secondary-ss-color);
}

.bg-ssl-primary{
    background-color: var(--primary-ssl-color);
}
.bg-ssl-secondary{
    background-color: var(--secondary-ssl-color);
}
.bg-secondary-ssl-color{
    background-color: var(--secondary-ssl-color);
}

.bg-middle-banner{
    /* background: url(../images/Home/MiddleBanner.webp); */
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}


.secondary-ssl-color{
    color: var(--secondary-ssl-color);
}

.w80px{
    width: 80px;
}

.home-services-img{
    /* width: 100%; */
    width: 100px;
    max-width: 200px;
}
.border-ss-primary{
    border-top: 3px solid var(--primary-ss-color);
    border: none;
    height: 3px;
    opacity: 1;
    color: var(--primary-ss-color);
    background-color: var(--primary-ss-color);

}
.border-ss-white{
    border-top: 2px solid #fff;
    border: none;
    height: 2px;
    opacity: 1;
    color: #fff;
    background-color: #fff;

}

.text-book-app{
    color: white;
}

.height-book-app{
    height: 180px;
}

.fs-ss-29{
    font-size: 29px;
}

.fs-ss-92{
    font-size: 92px;
}

.fs-ss-68{
    font-size: 68px;
}

.fs-ss-58{
    font-size:58px;
}

.fs-ss-56{
    font-size:56px;
}

.fs-ss-52{
    font-size:52px;
}

.fs-ss-52-1{
    font-size:52px;
}

.fs-ss-50{
    font-size:50px;
}

.fs-ss-48{
    font-size: 48px;
}

.fs-ss-42{
    font-size: 42px;
}

.fs-ss-44{
    font-size: 44px;
}

.fs-ss-44-1{
    font-size: 44px;
}


.fs-ss-46{
    font-size: 46px;
}

.fs-ss-46-1{
    font-size: 46px;
}

.fs-ss-46-2{
    font-size: 46px;
}

.fs-ss-40{
    font-size: 40px;
}

.fs-ss-40-1{
    font-size: 40px;
}

.fs-ss-40-2{
    font-size: 40px;
}

.fs-ss-38{
    font-size: 38px;
}

.fs-ss-34{
    font-size: 34px;
}

.fs-ss-35{
    font-size: 35px;
}

.fs-ss-36{
    font-size: 36px;
}

.fs-ss-36-1{
    font-size: 36px;
}

.fs-ss-36-2{
    font-size: 36px;
}


.fs-ss-32{
    font-size: 32px;
}

.fs-ss-32-1{
    font-size: 32px;
}

.fs-ss-32-2{
    font-size: 32px;
}

.fs-ss-32-3{
    font-size: 32px;
}

.fs-ss-31{
    font-size: 31px;
}

.fs-ss-31-1{
    font-size: 31px;
}

.fs-ss-30{
    font-size: 30px;
}

.fs-ss-30-1{
    font-size: 30px;
}

.fs-ss-30-2{
    font-size: 30px;
}

.fs-ss-30-3{
    font-size: 30px;
}

.fs-ss-28{
    font-size: 28px;
}

.fs-ss-28-1{
    font-size: 28px;
}

.fs-ss-28-2{
    font-size: 28px;
}

.fs-ss-28-3{
    font-size: 28px;
}

.fs-ss-28-4{
    font-size: 28px;
}

.fs-ss-26{
    font-size: 26px !important;
}

.fs-ss-26-1{
    font-size: 26px;
}

.fs-ss-26-2{
    font-size: 26px;
}

.fs-ss-26-3{
    font-size: 26px;
}

.fs-ss-26-4{
    font-size: 26px;
}

.fs-ss-24{
    font-size: 24px;
}

.fs-ss-24-1{
    font-size: 24px;
}

.fs-ss-22{
    font-size: 22px;
}

.fs-ss-22-1{
    font-size: 22px;
}

.fs-ss-22-2{
    font-size: 22px;
}

.fs-ss-19{
    font-size: 19px;
}

.fs-ss-20{
    font-size: 20px;
}

.fs-ss-20-1{
    font-size: 20px;
}

.fs-ss-20-2{
    font-size: 20px;
}

.fs-ss-18-1{
    font-size: 18px;
}

.fs-ss-18{
    font-size: 18px;
}

.fs-ss-18-2{
    font-size: 18px;
}

.fs-ss-16{
    font-size: 16px ;
}

.fs-ss-16-1{
    font-size: 16px;
}

.fs-ss-16-2{
    font-size: 16px;
}

.fs-ss-16-3{
    font-size: 16px;
}

.fs-ss-17{
    font-size: 17px;
}

.fs-ss-17-1{
    font-size: 17px;
}

.fs-ss-17-2{
    font-size: 17px;
}


.fs-ss-15{
    font-size: 15px;
}

.fs-ss-14{
    font-size: 14px;
}

.fs-ss-14-1{
    font-size: 14px;
}

.fs-ss-14-2{
    font-size: 14px;
}

.fs-ss-14-3{
    font-size: 14px;
}

.fs-ss-13{
    font-size: 13px;
}

.fs-ss-12{
    font-size: 12px;
}

.logo-img{
    height:60px;
}

.container-ss{
    max-width: 90%;
    padding-right: 5%;
    padding-left: 5%;
    margin-right: auto;
    margin-left: auto;
}

.container-ss2{
    max-width: 86%;
    padding-right: 7%;
    padding-left: 7%;
    margin-right: auto;
    margin-left: auto;
}

#website-nav .navbar-toggler:focus{
    outline: none;
    box-shadow: none;
}

#website-nav .navbar-toggler{
    border: none;
    border-radius: 0;
    background-color: #fff;
}

#website-nav .nav-link{
    color: #000;
    margin-right: 16px;
    padding-top: 0;
    padding-bottom: 0;
}


#website-nav .sub-nav-menu:hover .dropdown-menu {
    display: block;
}

#website-nav .sub-nav-menu:hover .sub-sub-child-menu .dropdown-menu {
    display: none;
}

#website-nav .sub-nav-menu .dropdown-menu {
    margin-top: 0;
}
#website-nav .sub-child-menu:hover .dropdown-menu {
    display: block;
    visibility: visible;
    margin-top: -10px;
}

#website-nav .sub-child-menu .sub-sub-child-menu:hover .dropdown-menu{
    display: block;
    visibility: visible;
}

#website-nav .active{
    color: var(--primary-ss-color);
}

#website-nav .dropdown-toggle{
    padding-right: 0;
    padding-left: 0;
    margin-right: 0;
    margin-left: 0;
}

.dropdown-item .active, .dropdown-item:active{
    background-color: var(--primary-ss-color);
}

#website-nav .sub-child-menu .dropdown-menu {
    /* display: none; */
    visibility: hidden;
    margin-top: 0;
}
#website-nav .sub-child-menu .dropdown-menu a{
    padding: 5px 10px;
    font-size: 15px;

}
#website-nav .sub-child-menu .dropdown-menu{
    width: 240px;
}
#website-responsive .dropdown-item{
    cursor: pointer;
}
.navbar-offcanvas{
    /* background-color: var(--primary-ss-color);
    color: #fff; */
    padding: 0rem;
}

.take-control-bg{
    background-color: var(--primary-ssl-color) ;
    /* background color to -10vw */
    background-position: 0 -10vw;
}
.minusBg10 {
    margin-top: -100px;
}

.minusBg12 {
    margin-top: -120px;
}

.lh-1-2{
    line-height: 1.2;
}

.lh-1-4{
    line-height: 1.4;
}

.lh-1{
    line-height: 1;
}

/* horizon clinic*/
.lh-1-8{
    line-height: 1.85714285714286;
}


.lb{
    margin-top: 30px;
    margin-left: 5px;
    padding-left: 30px;
    border-left: 4px solid #004bda;
}



.slick-slide-title {
    color: #fff;
    padding: 10px;
    position: absolute;
    /* text-transform: ; */
    left: 8vw;
    font-size: 3.2vw;
    font-family: OpenSauceOne;
    font-weight: 700;
    top: 10%;
    width: 80%;
    line-height: 1.1;
    letter-spacing: 2px;
  }
  .slick-slide-title-desc {
    color: #fff;
    padding: 10px;
    position: absolute;
    left: 8vw;
    font-size: 3.5vw;
    font-weight: 500;
    top: 48%;
    width: 80%;
  }
  .slick-slide-btn{
    position: absolute;
    left: 9vw;
    font-weight: 500;
    top: 67%;
    width: 80%;
  }

  .slick-slide-button{
    font-size: 1.4vw;
  }
  


.bg-contact-opacity{
    background-color: #181818;
    opacity: 0.95;
}

.text-white{
    color: #ffffff;
}
.banner-section{
    position: relative;
}
.about-col-bg-white{
    background-color: #ffffff;
    border-radius: 10px;
    align-content: center;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 50px;
    padding-bottom: 50px;
}
.about-col-b{
    border: 3px solid #004bda;
    border-radius: 10px;
    align-content: center;
}

.w80px {
    width: 80px;
}

.border-header{
    border-bottom: 4px solid var(--secondary-ss-color) !important;
}

.border-ss-primary2 {
    background-color: var(--primary-ss-color);
    border: none;
    color: var(--primary-ss-color);
    height: 2px;
    opacity: 1;
}
.accordion-button{
    padding: 10px 20px !important;
}
.accordion-button:focus{
    box-shadow: 0;
}
.accordion-button:not(.collapsed){
    background-color: #ededed !important;
}
.accordion-button:focus{
    box-shadow: none !important;
}
.accordion-item {
    border-left: 0 !important;
    border-radius: 0 !important;
    border-right: 0 !important;
    border-top: 0 !important;
}

.accordion{
    border: 0;
}

.splash-screen {
    position: absolute;
    z-index: 1000;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background-color: #f2f3f8;
    color: #5E6278;
    line-height: 1;
    font-size: 14px;
    font-weight: 400;
}
  
.splash-screen span {
color: #5E6278;
transition: none !important;
text-size-adjust: 100%;
-webkit-font-smoothing: antialiased;
}

.splash-screen img {
margin-left: calc(100vw - 100%);
margin-bottom: 30px;
height: 38px !important;
}

.slick-slide-image {
    max-width: 100%;
    height: auto;
    box-shadow: 0 13px 27px -5px hsla(240, 30.1%, 28%, 0.25), 0 8px 16px -8px hsla(0, 0%, 0%, 0.3),
      0 -6px 16px -6px hsla(0, 0%, 0%, 0.03);
  }
  .slick-slide-label {
    color: #fff;
    padding: 10px;
    position: absolute;
    left: 0px;
    font-size: 1.5em;
    bottom: 0px;
    width: 100%;
  }
  .slick-prev:before,
  .slick-next:before {
    color: #01054e !important;
  }

  .slick-slide-content{
    color: #fff;
    position: absolute;
    left: 7vw;
    top: 30%;
    width: 45%;

}


.slider-wrapper {
    width: 100%;

    margin: auto;
  }

  .btn-ss-white{
    background-color: var(--secondary-ss-color);
    color: white;
    border: 0;
    border-radius: 10;
    padding: 10px 20px;
    /* font-size: 1.1vw; */
    font-weight: 500;
  }


.btn-ss-white:hover{
    background-color: white;
    color: var(--secondary-ss-color);
    border: 1;
    padding: 10px 20px;
    /* font-size: 1.1vw; */
    font-weight: 500;
}

.btn-ss-white:focus{
    background-color: white;
    color: var(--secondary-ss-color);
    border: 1;
    padding: 10px 20px;
    /* font-size: 1.1vw; */
    font-weight: 500;
}

.btn-ss-white1:hover{
    background-color: white;
    color: var(--secondary-ss-color);
    border: 1;
    padding: 0px 0px;
    /* font-size: 1.1vw; */
    font-weight: 500;
}

.btn-ss-white1:focus{
    background-color: white;
    color: var(--secondary-ss-color);
    border: 1;
    padding: 3px 5px;
    /* font-size: 1.1vw; */
    font-weight: 500;
}

.btn-padding{
    padding: 5px 30px !important;
}

.home-banner .row{
    margin: 0;
}

.spacer-ss-15{
    height: 15px;
}

.spacer-ss-25{
    height: 25px;
}

.spacer-ss-37{
    height: 37px;
}

.footer-links-section{
    padding-top: 200px;
    padding-bottom: 40px;
}
.border-ss-secondary-footer{
    background-color: var(--secondary-ss-color);
    border: none;
    color: var(--secondary-ss-color);
    height: 3px;
    opacity: 1;
    width: 100px;
}
.border-gray2{
    border-top: 1px solid #ffffff40
}

  .rounded30{
    border-radius: 30px !important;
  }

  .rounded50{
    border-radius: 50%;
  }

  .rounded20{
    border-radius: 20px !important;
  }

  .rounded10{
    border-radius: 10px;
  }

  .rounded6{
    border-radius: 6px;
  }

  .btn-ss-outline-white{
    background-color: transparent;
    color: #fff;
    cursor: pointer;
    border-radius: 4px;
    /* height: 3em; */
    min-width: 120px;
    font-family: work-sans;
    font-weight: 600 !important;
    border: 1px solid #fff;

}
.btn-ss-primary{
    background-color: var(--primary-ss-color) !important;
    color: #fff;
    cursor: pointer !important;
    border: none !important;
}

.btn-ss-ads-page{
    background-color: transparent !important;
    color: #fff;
    cursor: pointer !important;
    border: 2px solid white !important;
}

.btn-ss-ads-page:hover{
    background-color: var(--primary-ss-color) !important;
    color: #fff;
    cursor: pointer !important;
}

.btn-ss-secondary{
    background-color: var(--secondary-ss-color) !important;
    color: #fff;
    cursor: pointer;
    border: none !important;
    padding: 8px 24px !important;
}

.btn-ss-secondary1{
    background-color: var(--secondary-ss-color);
    color: #fff;
    cursor: pointer;
    border: none;
    padding: 8px 20px;
}

.btn-ss-secondary:hover{
    background-color: var(--primary-ss-color);
    color: #fff;
    cursor: pointer;
    border: none;
}

.btn-ss-secondary1:hover{
    background-color: var(--primary-ss-color);
    color: #fff;
    cursor: pointer;
    border: none;
    padding: 8px 20px;
}

.btn-ss-secondary1:focus{
    background-color: var(--primary-ss-color);
    color: #fff;
    cursor: pointer;
    border: none;
    padding: 8px 20px;

}

.btn-ss-primary-banner{
    background-color: var(--primary-ss-color);
    color: #fff;
    cursor: pointer;
    /* padding: 2vw 5vw; */
    text-decoration: none;
    font-size: 15px;
    font-weight: 500;
    font-family: 'Work Sans', sans-serif;
    text-transform: capitalize !important;
    letter-spacing: 0.5px;
}

.btn-ss-primary-banner:hover{
    background-color: var(--primary-ss-color);
    opacity: 0.94;
    color: #fff;
    cursor: pointer;
    padding: 1vw 2vw;
}

.btn-ss-white-banner{
    background-color: var(--secondary-ss-color);
    color: #fff;
    cursor: pointer;
    text-decoration: none;
    font-size: 18px;
    font-weight: 600;
    font-family: 'Roboto', sans-serif;
    text-transform: capitalize !important;
    letter-spacing: 0.5px;
    padding: 8px 20px;
    border-radius: 10px;
}

.btn-ss-white-banner:hover{
    background-color: #fff;
    opacity: 0.94;
    color: var(--secondary-ss-color);
    cursor: pointer;
    /* padding: 1vw 2vw; */
}
.home-background-banner{
    /* background-image: url('../images/home/banner.png'); */
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    height: 82vh;
    width: 100%;
    position: relative;
}
.home-banner-content{
    position: absolute;
    top: 15%;
    width: 55%;
    left: 13%;
}
.home-banner-heading{
    letter-spacing: 3px;
}
.home-banner-btn{
    font-size: 18px;
    border-radius: 30px !important;
}
.btn-ss-primary:hover{
    background-color: var(--primary-ss-color);
    opacity: 0.94;
    color: #fff;
    cursor: pointer;
}

.btn-ss-secondary:hover{
    background-color: var(--primary-ss-color);
    opacity: 0.94;
    color: #fff;
    cursor: pointer;
}

.btn-ss-secondary:focus{
    background-color: var(--primary-ss-color) !important;
    opacity: 0.94;
    color: #fff;
    cursor: pointer;
}

.shadow-ss-1{
    box-shadow: 2px 3px 3px 2px #dadada !important;
}
.shadow-ss-2{
    box-shadow: 2px 5px 5px 4px #dadada !important
}

.border-zk-card-bottom{
    border-bottom: 5px solid var(--primary-ss-color);
    border-left: 0;
    border-right: 0;
    border-top: 0;
}

.ccm-excellence{
    /* background-image: url('../images/Home/ccmexcellence.webp'); */
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.padding10xem{
    padding: 10em;
}

.padding7xem{
    padding: 7em;
}

.padding5xem{
    padding: 5em;
}

.padding2-2xem{
    padding: 2.2em;
}
.padding3xem{
    padding: 3em;
}

.padding3xem-1{
    padding: 3em;
}


.navbar-nav .dropdown-menu {
    background: #fdfdfd;
    border: rgb(255, 255, 255);
    box-shadow: #dadada 0px 0px 2px 0px;
    border-radius: 0;
    border-top: 2px solid var(--primary-ss-color);
    position: static;
    width: 100%;
    /* font-family: Raleway;
    font-size: 16px; */
}

#website-nav .sub-nav-menu:hover .dropdown-menu {
    display: block;
}
#website-nav .sub-nav-menu .dropdown-menu {
    width: max-content;
    margin-top: 0;
}

.emr_featured_col{
    /* middle */
    min-height: 55px;

}

.card-stretch{
    height: calc(100% - 1px);
}



.home-mobile-pic{
    width: 30%;
}
.page-banner-title{
    color: #fff;
    /* padding: 10px; */
    position: absolute;
    font-family: OpenSauceOne;
    font-weight: bolder;
    font-size: 3vw;
    top: 40%;
    letter-spacing: 1px;
    color: #000;
    /* width: 80%; */
  }
  .page-banner-text1{
    font-size: 2vw;
  }
  .page-banner-desc{
    font-size: 1.4vw;
    font-weight: 400;
    margin-top: 10px;
    /* margin-top: 1px; */




  }

.navbar-nav .dropdown-menu {
    box-shadow: 0;
    border-radius: 0;
    border: 0;
    padding: 10px;
}

.digital-marketing-icon{
    width: 50% !important;
}
.about-weuse-section{
    min-height: 175px !important;
}

.about-ourphilosophy{
    width: 130px;
    height: 120px;
}
.home-healthimages{
    width: 40%;
}
.home-practice-img{
    width: 100%;
}

.home-practice-animation{
    width: 50%;
}
.whoweserve-healthimages{
    width: 25%;
} 

.about-connection-sec{
    min-height: 460px;
}
.marginTopvitals1{
    margin-top: -180px;
}

.paddingTopvitals2{
    padding-top: 190px;
}

.p-top{
    padding-top: 80px;
}

.p-bottom{
    padding-bottom: 80px;
}

.p-bottom1{
    padding-bottom: 60px;
}

.section1{
    background-position: center left;
    background-repeat: no-repeat;
    background-size: contain;
    min-height: 500px;
}

.section2{
    background-position: center right;
    background-repeat: no-repeat;
    background-size: contain;
    min-height: 500px;
}



.table-bordered{
    border: 1px solid #d3d3d3 !important;
}

.border-bottom{
    border-bottom: 1px solid grey !important;  
}

.shadow-os{
   box-shadow: -3px 3px 4px 1px rgb(0 0 0 / 30%);
}

.icon-size{
    width: 51px;
}


.blog-img{
    width: 50%;
}
.fw-900{
    font-weight: 900;
}

.fw-800{
    font-weight: 800;
}

.fw-700{
    font-weight: 700;
}


.fw-600{
    font-weight: 600;
}

.fw-500{
    font-weight: 500;
}

.fw-400{
    font-weight: 400;
}

.fw-300{
    font-weight: 300;
}

.lh-44{
    line-height: 44px;
}

.section1 .row{
    margin-right: 0;
    margin-left: 0;
}

.btn-ss-white-secondary{
    background-color: #fff;
    border: 3px solid var(--primary-ss-color);
}
.btn-ss-white-secondary:hover{
    background-color: var(--secondary-ss-color);
    color: #fff;
}

.ls-lg-ss-aim{
    letter-spacing: 16px;
}

.provider-img{
    width: 70%;
}

.provider-bg{
    background: linear-gradient(var(--primary-ss-color) 0 50%, white 50% 100%);
    border-radius: 6%;
    padding: 40px 10px 20px 10px;
    box-shadow: 2px 3px 3px 2px #dadada !important;
    margin: 0;
}
.provider-pic-w{
    max-width: 65%;
}
.provider-sec-bg{
    background-color: #F2F5F7;
}

.provider-col-bg{
    background: white;
    border-radius: 0%;
    padding: 20px 10px 5px 10px;
    box-shadow: 1px 1px 2px 2px #dadada;
    margin: 0;
}
.pa{
    position: absolute;
    top: -450px;
}

.home-insurance-img{
    width: 75%;
}

.footer-bg{
    background-color: #d3d3d3;
}

.border-zk-secondary-footer{
    width: 100px;
    height: 3px;
    background-color: var(--secondary-ss-color);
    margin-top: 10px;
    opacity: 1;
    border: var(--secondary-ss-color);
}

.footer-logo{
    width: 250px;
}
.supernova{
    background-color: #ebebff00 !important;
}
.no-row-space .row{
    padding-left: 0;
    padding-right: 0;
    margin-left: 0;
    margin-right: 0;
}

.bg-none{
    background-color: rgb(0,0,0,0.1);
}

.bg-above-footer{
    /* background: url(../images/Home/AboveFooterBannerImg.webp); */
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.medicatl-texting ::marker{
    color: var(--secondary-ss-color);
    font-size: 25px;
}

@media screen and (min-width: 1650px) {
    #website-responsive .left-padding7rem1{
        padding-left: 18rem;
    }

    #website-responsive .right-padding7rem1{
        padding-right: 18rem;
    }

    #website-responsive .left-padding10rem1{
        padding-left: 18rem;
    }

    #website-responsive .right-padding10rem1{
        padding-right: 18rem;
    }

    #website-responsive .left-padding7rem{
        padding-left: 18rem;
    }

    #website-responsive .left-padding6rem{
        padding-left: 18rem;
    }

    #website-responsive .left-padding6rem1{
        padding-left: 18rem;
    }

    #website-responsive .right-padding7rem{
        padding-right: 18rem;
    }

    #website-responsive .right-padding6rem{
        padding-right: 18rem;
    }

    #website-responsive .right-padding6rem1{
        padding-right: 18rem;
    }

    #website-responsive .about-text-padding{
        padding-top: 12rem;
        padding-left: 18rem;
        padding-bottom: 10rem;
      }

    #website-responsive .well-visit-text-padding{
        padding-top: 12rem;
        padding-left: 10em;
        padding-bottom: 12rem;
      } 
      
      #website-responsive .psychiatry-text-padding{
        padding-top: 12rem;
        padding-left: 10em;
        padding-bottom: 12rem;
      } 
}

@media screen and (min-width: 1440px) {
    #website-responsive .left-padding7rem1{
        padding-left: 9.5rem;
    }

    #website-responsive .right-padding7rem1{
        padding-right: 9.5rem;
    }

    #website-responsive .left-padding7rem{
        padding-left: 9.5rem;
    }

    #website-responsive .left-padding6rem{
        padding-left: 9.5rem;
    }

    #website-responsive .right-padding7rem{
        padding-right: 9.5rem;
    }

    #website-responsive .right-padding6rem{
        padding-right: 9.5rem;
    }

    #website-responsive .right-padding6rem1{
        padding-right: 9.5rem;
    }

    #website-responsive .about-text-padding{
        padding-top: 12rem;
        padding-left: 9.5rem;
        padding-bottom: 10rem;
      }
}

@media screen and (max-width: 1281px) {
    .home-marketing-section-content .fs-ss-16{
        font-size: 15px;
    }

    #website-nav .nav-link{
        margin-right: 10px;
    }

    #website-nav .fs-ss-16{
        font-size: 15px;
    }

    #website-responsive .reverse-section{
        display: flex;
        flex-direction: row;
    }
    
}
@media screen and (max-width: 1150px){

}

@media screen and (max-width: 1050px){
    #website-responsive .fs-ss-92{
        font-size: 55px;
    }

    #website-responsive .fs-ss-68{
        font-size: 45px;
    }

    .ls-lg-ss-aim{
        letter-spacing: 10px;
    }

}

@media screen and (max-width: 991px) {
    .about-text-padding1{
        padding-top: 11rem;
        padding-left: 2em !important;
        padding-bottom: 7rem;
      }
    .top-border{
        border-top: 2px solid var(--primary-ss-color);
    } 
    .container-ss{
        max-width: 96%;
        padding-right: 2%;
        padding-left: 2%;
        margin-right: auto;
        margin-left: auto;
    }
    .provider-bg{
        background: linear-gradient(var(--primary-ss-color) 0 30%, white 70% 100%);
    }
    .home-services-img{
        width: 120px;
    }
    .home-mobile-pic{
        width: 30%;
    }
    .logo-img{
        height:50px;
    }
    #website-responsive .minusBg10, #website-responsive .minusBg12 {
        margin-top: 0;
    }

    #website-responsive .fs-ss-46{
        font-size: 36px;
    }

    .home-insurance-img{
        width: 60%;
    }

    #website-responsive .fs-ss-92{
        font-size: 55px;
    }
    #website-responsive .service-healthcare-bg  .fs-ss-92{
        font-size: 45px;
    }

    #home-we-aim .fs-ss-92{
        font-size: 45px;
    }

    #website-responsive .fs-ss-68{
        font-size: 45px;
    }

    #website-responsive .fs-ss-56, #website-responsive .fs-ss-58{
        font-size:40px;
    }

    #website-responsive .fs-ss-52{
        font-size:38px;
    }


    #website-responsive .fs-ss-48{
        font-size:36px;
    }

    #website-responsive .fs-ss-44{
        font-size:26px;
    }

    #website-responsive .fs-ss-44-1{
        font-size:22px;
    }

    #website-responsive .fs-ss-40{
        font-size:32px;
    }

    #website-responsive .fs-ss-35{
        font-size:28px;
    }

    #website-responsive .fs-ss-17{
        font-size:14px;
    }

    #website-responsive .marginSetting{
        margin-left: 0rem;
        margin-right: 0rem;
    }

    #website-responsive .padding10xem, #website-responsive .padding7xem{
        padding: 1em;
    }

    #website-responsive .padding3xem{
        padding: 1em;
    }

    #website-responsive .padding3xem-1{
        padding: 40px;
    }

    #website-responsive .padding5xem{
        padding: 1em;
    }

    #website-responsive .padding2-2xem{
        padding: 1em;
    }

    #website-responsive  .padding0272rem{
        padding: 1rem;
    }
   

    #website-responsive .marginBottom{
        margin-bottom: 2rem;
    }

    /* .btn-ss-white{
        padding: 0;
    }
    .btn-ss-white:hover{
        padding: 0;
    }
    .btn-ss-white button{
        padding: 0;
    } */
    .page-banner-title{
        top:40%;
    }
    .about-weuse-section1, .about-weuse-section2, .about-weuse-section3, .about-weuse-section4{
        width: 100%;
        height: unset;
        min-height: unset;
        display: block;
        padding: 40px 30px !important;
        font-weight: 600;
    }
    .navbar{
        padding: unset;
    }
    
    /* .home-banner-content{
        position: absolute;
        top: 15%;
        width: 50%;
        left: 3%;
    } */
}

@media screen and (max-width: 767px) {
    .footer-logo{
        width: 170px;
        margin-bottom: 20px;
    }
    .spacer-ss-25{
        height: 15px;
    }
    .home-medical-img{
        width: 60%;
    }
    .home-billing-img{
        width: 80%;
    }
    .provider-pic-w{
        max-width: 150px;
    }
    .integrated-section-img-1, .integrated-section-img-2, .integrated-section-img-3, .integrated-section-img-4{
        background-size: cover;
        background-attachment: unset;
    }
    .review-section-img-1, .review-section-img-2, .review-section-img-3{
        background-size: cover;
        background-attachment: unset;
    }
    .appointment-section-img-1, .appointment-section-img-2, .appointment-section-img-3{
        background-size: cover;
        background-attachment: unset;
    }

    #website-responsive .mr-header{
        margin-right: 0rem;
    }

    #website-responsive .minusBg10 {
        margin-top: 0;
    }

    #website-responsive .fs-ss-46{
        font-size: 21px;
    }

    #website-responsive .reverse-section{
        display: flex;
        flex-direction: column-reverse;
    }

    #website-responsive .fs-ss-34{
        font-size: 28px;
    }

    #website-responsive .fs-ss-36{
        font-size: 26px;
    }

    #website-responsive .fs-ss-36-1{
        font-size: 28px;
    }

    #website-responsive .fs-ss-36-2{
        font-size: 22px;
    }

    #website-responsive .fs-ss-32{
        font-size: 20px;
    }

    #website-responsive .fs-ss-32-1{
        font-size: 22px;
    }

    #website-responsive .fs-ss-32-2{
        font-size: 26px;
    }

    #website-responsive .fs-ss-14{
        font-size: 11px;
    }

    #website-responsive .fs-ss-14-2{
        font-size: 13px;
    }

    #website-responsive .fs-ss-14-3{
        font-size: 14px;
    }

    /* #website-responsive .fs-ss-14-1{
        font-size: 11px;
    } */

    #website-responsive .fs-ss-18{
        font-size: 16px;
    }
    
    #website-responsive .left-padding5rem{
        padding-left: 0rem;
    }

    #website-responsive .right-padding5rem{
        padding-right: 0rem;
    }

    #website-responsive .left-padding2rem{
        padding-left: 0rem;
    }

    #website-responsive .right-padding2rem{
        padding-right: 0rem;
    }


    #website-responsive .left-padding5rem1{
        padding-left: 1rem;
    }

    #website-responsive .right-padding5rem1{
        padding-right: 1rem;
    }

    #website-responsive .left-padding7rem{
        padding-left: 1rem;
    }

    #website-responsive .left-padding7rem-res{
        padding-left: 30px;
    }

    #website-responsive .right-padding7rem-res{
        padding-right: 30px;
    }
    

    #website-responsive .left-padding4rem{
        padding-left: 1rem;
    }

    #website-responsive .left-padding7rem1{
        padding-left: 20px;
    }

    #website-responsive .left-padding8rem1{
        padding-left: 20px;
    }

    #website-responsive .right-padding8rem1{
        padding-right: 20px;
    }

    #website-responsive .left-padding10rem1{
        padding-left: 20px;
    }

    #website-responsive .right-padding10rem1{
        padding-right: 20px;
    }

    #website-responsive .right-padding7rem{
        padding-right: 1rem;
    }

    #website-responsive .right-padding6rem{
        padding-right: 1rem;
    }

    #website-responsive .right-padding6rem1{
        padding-right: 30px;
    }
    
    #website-responsive .right-padding4rem{
        padding-right: 1rem;
    }

    #website-responsive .left-padding6rem{
        padding-left: 1rem;
    }

    #website-responsive .left-padding6rem1{
        padding-left: 30px;
    }

    #website-responsive .right-padding7rem1{
        padding-right: 20px;
    }

    #website-responsive .p-left-right{
        padding-left: 30px;
        padding-right: 30px;
    }

    #website-responsive .p-top2rem{
        padding-top: 2rem;
    }

    #website-responsive .p-top2rem1{
        padding-top: 1rem;
    }

    #website-responsive .display-none{
        display: none;
    }

    #website-responsive .display-none1{
        display: none;
    }

    #website-responsive .display-block{
        display: block;
    }

    #website-responsive .text-align-center{
        text-align: start;
    }

    #website-responsive .p-top-bottom2rem{
        padding-top: 2rem;
        padding-bottom: 2rem;
      }

      #website-responsive .p-top-bottom2rem3{
        padding-top: 2rem;
        padding-bottom: 2rem;
      }

      #website-responsive .p-top-bottom2rem4{
        padding-top: 4rem;
        padding-bottom: 4rem;
      }
     
      #website-responsive .p-top-bottom2rem1{
        padding-top: 2rem;
        padding-bottom: 3rem;
      }  

      #website-responsive .p-top-bottom2rem2{
        padding-top: 2rem;
        padding-bottom: 3rem;
      }  

    #website-responsive .flex-center1{
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 6px;
    
    }

    #website-responsive .height-display-banner{
        height: 39vh;
    }

    #website-responsive .height-display-banner-urgent{
        height: 39vh;
    }

    #website-responsive .flex-center2{
        display: flex;
        justify-content: start;
        align-items: center;
    
    }

    #website-responsive .flex-center4{
        display: flex;
        justify-content: start;
        /* align-items: center; */
    
    }

    #website-responsive  .top-margin{
        margin-top: 1rem;
    }

    #website-responsive .marginLeft{
        margin-left: 0px;
    }  
    
    #website-responsive .flex-center1{
        display: flex;
        justify-content: center;
        align-items: center;
    
    }

    #website-responsive .about-padding{
        padding: 60px 40px 80px 40px;
      }

      #website-responsive .about-padding2{
        padding: 60px 30px 60px 40px;
      }  

    #website-responsive .about-padding1{
        padding: 40px;
    }  

    #website-responsive .about-margin{
        margin-left: 0rem;
        margin-right: 0rem
    }  

    #website-responsive .about-max-width{
        max-width: 200px;
    }

    #website-responsive .boaz-max-width{
        max-width: 110px;
    }

    #website-responsive .albert-max-width{
        max-width: 170px;
    }

    #website-responsive .vaccine-max-width{
        max-width: 170px;
    }

    #website-responsive .psychiatry-max-width{
        max-width: 250px;
    }

    /* #website-responsive .financial-max-width{
        max-width: 170px;
    } */

    #website-responsive  .home-text-padding{
        padding-top: 1rem;
        padding-left: 1rem;
        padding-bottom: 1rem;
      }

      #website-responsive  .contact-text-padding{
        padding-top: 6rem;
        padding-left: 13rem;
        padding-bottom: 6rem;
      }

      #website-responsive  .provider-text-padding{
        padding-top: 2rem;
        padding-left: 1rem;
        padding-bottom: 2rem;
      }

      #website-responsive  .about-text-padding{
        padding-top: 3rem;
        padding-left: 1rem;
        padding-bottom: 4rem;
      }

      #website-responsive  .boaz-text-padding{
        padding-top: 3rem;
        padding-left: 1rem;
        padding-bottom: 3rem;
      }

      #website-responsive  .albert-text-padding{
        padding-top: 4rem;
        padding-left: 1rem;
        padding-bottom: 4rem;
      }

      #website-responsive  .psychiatry-text-padding{
        padding-top: 3rem;
        padding-left: 1rem;
        padding-bottom: 4rem;
      }

      #website-responsive  .well-visit-text-padding{
        padding-top: 6rem;
        padding-left: 1rem;
        padding-bottom: 6rem;
      }

      #website-responsive  .well-visit-text-padding{
        padding-top: 6rem;
        padding-left: 1rem;
        padding-bottom: 6rem;
      }

      #website-responsive  .about-text-padding2{
        padding-top: 3rem;
        padding-left: 0.2rem;
        padding-right: 0rem;
        padding-bottom: 0rem;
      }

      #website-responsive .about-p-img{
        padding-top: 1.5rem;
        padding-left: 0rem;
      }

      #website-responsive .about-p-img1{
        padding-top: 1.5rem;
        padding-left: 0rem;
      }

      #website-responsive .about-t-padding{
        padding: 1rem;
        padding-top: 3.5rem;
        padding-bottom: 21rem;
      }

      #website-responsive .about-t-bg{
        background-position:bottom center !important;
        background-repeat: no-repeat !important;;
        background-size: 56% !important;;
      }

      #website-responsive  .privacy-text-padding{
        padding-top: 4rem;
        padding-left: 1rem;
        padding-bottom: 4rem;
      }

    #website-responsive .display-block1{
        display: block;
        justify-content: center;
      }  

    .home-practice-img{
        width: 40%;
    }
    .home-practice-animation{
        width: 70%;
    }

    #website-responsive .btn-ss-secondary1{
        background-color: white;
        color: black;
        cursor: pointer;
        border: 2px solid #d74100;
        width: 100%;
        padding: 10px 0px;
        border-radius: 0px;
    }

    #website-responsive .fs-ss-20{
        font-size:16px;
    }

    #website-responsive .fs-ss-20-1{
        font-size:14px;
    }

    .slick-slide-content{
        top: 35%;
        width: 64%;
        left: 2vw;
    }
    .slick-slide-title-desc{
        font-size: 2.7vw;
        font-weight: 500;
        padding: 0 10px 3px 10px;
    }

    .slick-slide-title{
        font-size: 4vw;
        padding: 0 10px;
        top:20%;
        left: 2vw;
        line-height: 27px;
    }
    .slick-slide-btn{
        top: 60%;
    }
    
    .slick-slide-button{
        font-size: 2vw;
      }

    .home-banner-btn{
        font-size: 12px !important;
    }
    .page-banner-btn{
        margin-top: 10px;
    }
    
    #website-responsive .btn-ss-white-banner{
        font-size: 11px;
        padding: 6px 10px;
        color: white;
        background-color: var(--secondary-ss-color);
        /* margin-top: 20px; */
    }
    .btn-ss-primary-banner{
        font-size: 14px;
        margin-top: 20px;
    }
    
    .digital-marketing-icon{
        width: 60% !important;
    }
    .about-weuse-section{
        min-height: 100% !important;
    }
    .home-healthimages{
        width: 30%;
    }

    .home-healthimages img{
        width: 30%;
    }
    .page-banner-title{
        font-size: 4vw;
        top: 40%;
        line-height: 5.5vw;
    }
    .page-banner-text1{
        font-size: 3vw;
    }
    .page-banner-desc{
        font-size: 3vw;
        font-weight: 500;
        display: none;
        line-height: 3vw;
    }
    .about-connection-sec{
        min-height: 100%;
    }
    .text-home-onestop{
        font-size: 24px !important; 
    }
    .about-ourphilosophy{
        width: 100px;
        height: 80px;
    }
    #reviews-lb .lb{
        margin-top: 7px;
        margin-left: 0;
        padding-left: 0;
        border-left: unset;
    }

    .marginTopvitals1{
        margin-top: -110px;
    }
    
    .paddingTopvitals2{
        padding-top: 120px;
    }

    .emr_featured_col{
        /* middle */
        min-height: 0px;
    
    }
    .footer-links-section{
        padding-bottom: 0;
    }
    .ls-lg-ss-aim{
        letter-spacing: 2px;
    }

    .home-insurance-img{
        width: 50%;
    }
    .about-timing-bg{
        background: none;
    }

    #whatisccm-comprehensive .container-ss{
        max-width: 100% !important;
        padding-right: 0 !important;
        padding-left: 0 !important;
        margin-right: auto;
        margin-left: auto;

    }
    
}
  

@media screen and (max-width: 576px) {
    
 .home-banner-1{
    background-repeat: no-repeat;
    background-position: center !important;
    background-size: cover;
    position: relative;
    background-image: url(../images/home/Home-Mobile-Slider-pic-1-v2.png) !important;
 }

 .home-banner-2{
    background-repeat: no-repeat;
    background-position: center !important;
    background-size: cover;
    position: relative;
    background-image: url(../images/home/Home-Mobile-Slider-pic-2-v2.png) !important;
 }
    #website-responsive .right-menu{
        
        &:hover{
         .hover1{
             display: block;
             max-width: 100%;
             padding: 0px;
             border-top: 2px solid var(--primary-ss-color);
             box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.25);
         }
        } 
     }

    #website-responsove .m-width{
        max-width: 100px;
    } 

    .top-border{
        border-top: 2px solid var(--primary-ss-color);
    } 

    .bottom-border{
        border-bottom: 1px solid lightgray;
    }
    
    .bottom-border1{
        border-bottom: 1px solid lightgray;
    }

    
    .p-left-header{
        padding-left: 2rem !important;
    }

    #website-responsive .p-top{
        padding-top: 20px;
    }

    #website-responsive .p-top-responsive{
        padding-top: 35px;
    }

    #website-responsive .p-bottom{
        padding-bottom: 20px;
    }

    #website-responsive .p-bottom1{
        padding-bottom: 20px;
    }

    #website-responsive .btn-padding{
        padding: 5px 23px !important;
    }

    #website-responsive .bg-img-h{
        height: 100%;
     }

    .section1{
        min-height: 100%;
    }
    .section2{
        min-height: 100%;
    }
    .provider-img{
        width: 40%;
    }

    .slick-slide-title{
        font-size: 5vw;
        top:20%;
        line-height: 17px;
        left: 1vw;

    }

    .slick-slide-title-desc{
        top:45%;
        font-size: 4vw;
        font-weight: 500;
        
    }

    .slick-slide-title2{
        font-size: 3.7vw;
        top:20%;
        line-height: 17px;
        left: 1vw;
    }

    .slick-slide-btn{
        top: 60%;
        margin-left: 10px;
    }
    

    .slick-slide-button{
        font-size: 3.5vw;
      }

      .home-services-img{
        width:120px;
      }
    .home-mobile-pic{
        width: 50%;
    }

    #website-responsive .family-padding{
        padding: 40px 20px 40px 20px;
     }

     #website-responsive .family-padding1{
        padding: 40px 20px 40px 20px;
     }

     #website-responsive .urgent-padding{
        padding: 40px 20px 40px 20px;
     }

     #website-responsive .urgent-padding1{
        padding: 40px 20px 40px 20px;
     }

     #website-responsive .pediatric-padding{
        padding: 40px 20px 40px 20px;
     }

     #website-responsive .pediatric-padding1{
        padding: 40px 20px 40px 20px;
     }

    #website-responsive  .marginLeftServices{
        margin-left: 0px;
     }
     
    #website-responsive .top-header-grid-center{
        display: grid;
        justify-content: center;
        align-items: center;
    }

    #website-responsive .bg-img1{
        background-position: right;
        background-repeat: no-repeat;
        background-size: cover;
    }

    #website-responsive .bg-img4{
        background-position: left;
        background-repeat: no-repeat;
        background-size: cover;
    }

    #website-responsive .contact-table-margin{
        margin-left: 0rem;
        padding-left: 0rem;
        border-width: 0px;
        margin-top: 3rem;
      }

    #website-responsive  .text-book-app{
        color: black;
    }  

    #website-responsive  .height-book-app{
        height: 120px;
    }  

    #website-responsive .fs-ss-92{
        font-size: 45px;
    }

    #website-responsive .fs-ss-29{
        font-size: 20px;
    }

    #website-responsive .fs-ss-31-1{
        font-size: 26px;
    }
    

    #website-responsive .service-healthcare-bg  .fs-ss-92{
        font-size: 35px;
    }

    #home-we-aim .fs-ss-92{
        font-size: 35px;
    }

    #services-cta .fs-ss-92{
        font-size: 35px;
    }

    #website-responsive .fs-ss-68{
        font-size: 32px;
    }

    
    #website-responsive .fs-ss-56, #website-responsive .fs-ss-58{
        font-size:36px;
    }
    #website-responsive .fs-ss-52{
        font-size:28px;
    }

    #website-responsive .fs-ss-52-1{
        font-size:36px;
    }
    
    #website-responsive .fs-ss-48{
        font-size: 26px;
    }

    #website-responsive .fs-ss-42{
        font-size: 26px;
    }

    #website-responsive .fs-ss-40{
        font-size: 24px;
    }

    #website-responsive .fs-ss-40-1{
        font-size: 26px;
    }

    #website-responsive .fs-ss-40-2{
        font-size: 22px;
    }

    #website-responsive .fs-ss-35{
        font-size: 22px;
    }

    #website-responsive .fs-ss-30, #website-responsive .fs-ss-28{
        font-size: 20px;
    }

    #website-responsive .fs-ss-28-1{
        font-size: 15px;
    }

    #website-responsive .fs-ss-28-2{
        font-size: 24px;
    }

    #website-responsive .fs-ss-28-3{
        font-size: 26px;
    }

    #website-responsive .fs-ss-28-4{
        font-size: 21px;
    }

    #website-responsive .fs-ss-30-1{
        font-size: 26px;
    }

    #website-responsive .fs-ss-30-2{
        font-size: 22px;
    }

    #website-responsive .fs-ss-30-3{
        font-size: 24px;
    }

    #website-responsive .fs-ss-32{
        font-size: 26px;
    }

    #website-responsive .fs-ss-32-1{
        font-size: 22px;
    }

    #website-responsive .fs-ss-32-3{
        font-size: 28px;
    }

    #website-responsive .top-header-margin-left{
        margin-left: 0rem;
    }

    #website-responsive .fs-ss-38{
        font-size: 24px;
    }

    #website-responsive .fs-ss-46{
        font-size: 21px;
    }

    #website-responsive .fs-ss-46-1{
        font-size: 36px;
    }

    #website-responsive .fs-ss-46-2{
        font-size: 20px;
    }

    #website-responsive .fs-ss-26, #website-responsive .fs-ss-24{
        font-size: 19px;
    }

    #website-responsive .fs-ss-24-1{
        font-size: 20px;
    }

    #website-responsive .fs-ss-26-1{
        font-size: 20px;
    }

    #website-responsive .fs-ss-26-2{
        font-size: 22px;
    }

    #website-responsive .fs-ss-26-3{
        font-size: 24px;
    }

    #website-responsive .services-margin-top{
        margin-top: 1rem;
    }

    #website-responsive .home-banner-max-width{
        max-width: 240px;
    }

    #website-responsive .blogs-banner-max-width{
        max-width: 220px;
    }

    #website-responsive .service-banner-max-width{
        max-width: 245px;
    }

    #website-responsive .about-services-max-width{
        max-width: 250px;
    }

    #website-responsive .about-banner-max-width{
        max-width: 320px;
    }

    #website-responsive  .provider-max-width{
        max-width: 200px;
        line-height: 1.5rem;
    }

    #website-responsive  .img-app{
        width :280px;
    }

    #website-responsive  .imgs-caring-hands{
        width :60%;
    }

    #website-responsive .left-padding3rem{
        padding-left: 1rem;
      }

    #website-responsive .right-padding3rem{
        padding-right: 1rem;
    }  

    #website-responsive .bgw{
        background-size: contain !important;
        width: 100%;
    }

    #website-responsive  .img-w1{
        width :170px;
      }
    
      #website-responsive  .img-w2{
        width :150px;
      }
    
      #website-responsive  .img-w3{
        width :100px;
      }
    
      #website-responsive  .img-w4{
        width :150px;
      }

      #website-responsive  .img-w5{
        width : 100%;
      }

      #website-responsive  .img-w6{
        width : 100%;
      }

      #website-responsive  .img-w7{
        width : 100%;
      }

      #website-responsive  .img-w8{
        width : 100%;
      }

      #website-responsive  .img-w9{
        width : 75%;
      }

      #website-responsive  .img-w10{
        width : 77%;
      }

      #website-responsive  .img-w11{
        width : 100%;
      }

      #website-responsive  .img-w12{
        width : 90%;
      }

      #website-responsive  .img-w13{
        width : 100%;
      }

      #website-responsive  .img-w14{
        width : 135px;
        height: auto;
      }

      #website-responsive .display-grid1{
        display: grid;
        justify-content: end;
        text-align: center;
        padding-right: 5rem;
        padding-top: 4rem;
        padding-bottom: 2rem;
    }

    #website-responsive .p-tb{
        padding: 40px 30px 40px 30px;
    }

    #website-responsive .p-tb1{
        padding: 40px 30px 40px 30px;
    }
    
    #website-responsive .p-tb2{
        padding-top: 5rem;
        padding-bottom: 3rem;
    }

    .btn-full-width{
        width: 100%;
    }

    #wesbsite-responsive .home-banner1{
        background-image: url("../../../public/home/Home-Mobile-Slider-pic-1-v2.png");
    }

    #wesbsite-responsive .about-banner1{
        background-image: url("../images/About-Mobile-Banner-v2.png");
    }

    #website-responsive .blogs-banner{
        background-image: url("../images/HMC-Blogs-Mobile-Banner-v1.webp");
    }

    #website-responsive .pediatric-banner{
        background-image: url("../images/HMC-Pediatric-Care-Service-Mobile-Banner-v1.jpg");
    }

    #website-responsive .sick-visit-banner{
        background-image: url("../images/Sick-Visits-Mobile-Banner-v1.1.png") !important;
    }

    .navbar-brand .fs-ss-26{
        font-size: 19px;
    }

    #website-responsive .fs-ss-50{
        font-size: 26px;
    }

    #website-responsive .fs-ss-22{
        font-size: 18px;
    }

    #website-responsive .fs-ss-22-1{
        font-size: 20px;
    }

    #website-responsive .fs-ss-22-2{
        font-size: 22px;
    }

    #website-responsive .fs-ss-16{
        font-size: 15px;
    }

    #website-responsive .fs-ss-16-1{
        font-size: 14px;
    }

    #website-responsive .fs-ss-16-3{
        font-size: 13px;
    }


    #website-responsive .fs-ss-18{
        font-size: 16px;
    }

    #website-responsive .fs-ss-18-1{
        font-size: 15px;
    }

    #website-responsive .fs-ss-17{
        font-size: 11px;
    }

    #website-responsive .fs-ss-17-1{
        font-size: 16px;
    }

    #website-responsive .fs-ss-17-2{
        font-size: 14px;
    }
    
    .about-health-section .fs-ss-30{
        font-size: 23px !important;
    }
    
    #website-responsive .border-style{
        border-width: 2px 0px 2px 0px      
    }

    #website-responsive .marginAll{
        margin: 1rem;
    }

    #website-responsive .img6-margin{
        margin-left: 20px;
     }

    .container-ss, .container-ss2{
        max-width: 96%;
        padding-right: 2% !important;
        padding-left: 2% !important;
        margin-right: auto;
        margin-left: auto;
    }
    
    #website-responsive .padding2-2xem{
        padding: 1em;
    }

    #website-responsive .max-width{
        max-width: 325px;
    }

    #website-responsive .image-width{
        width: 100%;
    }

    #website-responsive .marginX{
        margin: 5px;
    }
}



@media screen and (max-width: 480px) {
    .page-banner-title{
        top:40%;
    }
}

@media screen and (max-width: 440px) {
    .page-banner-title{
        top:40%;
    }
}

@media screen and (max-width: 400px) {
    .page-banner-title{
        top:40%;
    }
}

@media screen and (max-width: 320px) {
    .page-banner-title{
        top:35%;
    }
    
}

@media screen and (max-width: 373px) {
    #website-responsive .fs-ss-14-2{
        font-size: 12px;
    }

}

@media screen and (max-width: 280px) {
    
}



/* Horizon Clinic CSS */

.bg-img{
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.bg-img1{
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.bg-img2{
    background-position: right;
    background-repeat: no-repeat;
    background-size: cover;
}

.bg-img3{
    background-position: right;
    background-repeat: no-repeat;
    background-size: cover;
}

.bg-img4{
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.display-grid{
    display: grid;
    justify-content: end;
    text-align: center;
    padding-right: 14rem;
    padding-top: 4rem;
}

.display-grid1{
    display: grid;
    justify-content: end;
    text-align: center;
    padding-right: 14rem;
    padding-top: 4rem;
    padding-bottom: 2rem;
}

.flex-center{
    display: flex;
    justify-content: center;
    align-items: center;

}

.flex-center3{
    display: flex;
    justify-content: center;
}

.flex-center2{
    display: flex;
    justify-content: start;
    align-items: center;

}

.flex-center4{
    display: flex;
    justify-content: center;
}

.flex-center5{
    display: flex;
    justify-content: space-between;
    align-items: center;

}

/* .text-hover:hover{
    color: var(--secondary-ss-color);
} */

.text-hover-pri:hover{
    color: var(--primary-ss-color) !important;
}

.grid-center{
    display: grid;
    justify-content: center;
    align-items: center;
}

.top-header-grid-center{
    display: flex;
    justify-content: start;
    align-items: center;
}

.inline-center{
    display: inline;
    justify-content: center;
    align-items: center;
}

.hover1{
    /* @include NavbarMenu; */
    display: none;
}

.right-menu{
   &:hover{
    .hover1{
        display: block;
        max-width: 280px;
        padding: 0px;
        /* border-top: 2px solid var(--primary-ss-color); */
        box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.25);
    }
   } 
}

.short-details{
    display: block;
    width: 420px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .text-hover:hover{
    color: var(--secondary-ss-color);
  }

  .marginSetting{
    margin-left: 8rem;
    margin-right: 10rem;
  }

  .left-padding5rem{
    padding-left: 5rem;
  }

  .left-padding2rem{
    padding-left: 2rem;
  }

  .left-padding3rem{
    padding-left: 3rem;
  }

  .left-padding5rem1{
    padding-left: 5rem;
  }

  .left-padding2rem{
    padding-left: 2rem;
  }

  .right-padding5rem{
    padding-right: 5rem;
  }

  .right-padding2rem{
    padding-right: 2rem;
  }

  .right-padding3rem{
    padding-right: 3rem;
  }

  .right-padding5rem1{
    padding-right: 5rem;
  }

  .left-padding7rem{
    padding-left: 7rem;
  }

  .left-padding4rem{
    padding-left: 4rem;
  }

  .left-padding6rem{
    padding-left: 6rem;
  }

  .left-padding6rem1{
    padding-left: 6rem;
  }

  .left-padding7rem1{
    padding-left: 7rem;
  }

  .left-padding8rem1{
    padding-left: 8rem;
  }

  .right-padding8rem1{
    padding-right: 8rem;
  }

  .left-padding10rem1{
    padding-left: 10rem;
  }

  .right-padding10rem1{
    padding-right: 10rem;
  }

  .right-padding7rem{
    padding-right: 7rem;
  }

  
  .right-padding6rem{
    padding-right: 6rem;
  }

  .right-padding6rem1{
    padding-right: 6rem;
  }

  .right-padding4rem{
    padding-right: 4rem;
  }

  .right-padding7rem1{
    padding-right: 7rem;
  }

  .display-none{
    display: block;
  }

  .display-none1{
    display: flex;
    justify-content: end;
  }

  .display-block1{
      display: none;
  }

  .top-margin{
    margin-top: 12rem;
  }
  
  .marginLeft{
    margin-left: 105px;
  }

  .padding0272rem{
    padding: 0rem 7rem 2rem 2rem;
  }

  .max-width{
    max-width: 470px;
  }

  .home-banner-max-width{
    max-width: 480px;
  }

  .blogs-banner-max-width{
    max-width: 480px;
  }

  .about-services-max-width{
    max-width: 700px;
  }

  .about-max-width{
    max-width: 100%;
  }

  .boaz-max-width{
    max-width: 100%;
  }

  .albert-max-width{
    max-width: 100%;
  }

  .vaccine-max-width{
    max-width: 100%;
  }

  .financial-max-width{
    max-width: 76%;
  }

  .psychiatry-max-width{
    max-width: 350px;
  }

  .image-width{
    width: 75%;
  }

  .text-align-center{
    text-align: center;
  }

  .home-banner1{
    background-image: url("../images/Home-Slider-pic-1-v1.webp");
  }

  .sick-visit-banner{
    background-image: url("../images/Sick-Visit-Banner-v1.webp") !important;
  }

  .about-banner{
    background-image: url("../images/About-Banner-500px.webp");
  }

  .blogs-banner{
    background-image: url("../images/HMC-Blogs-Banner-v1.webp");
  }

  .home-text-padding{
    padding-top: 5rem;
    padding-left: 5rem;
    padding-bottom: 6rem;
  }

  .contact-text-padding{
    padding-top: 12rem;
    padding-left: 50rem;
    padding-bottom: 10rem;
  }

  .psychiatry-text-padding{
    padding-top: 5rem;
    padding-left: 7em;
    padding-bottom: 6rem;
  }

  .provider-text-padding{
    padding-top: 8rem;
    padding-left: 7rem;
    padding-bottom: 8rem;
  }

  .about-text-padding{
    padding-top: 12rem;
    padding-left: 7em;
    padding-bottom: 10rem;
  }

  .boaz-text-padding{
    padding-top: 14rem;
    padding-left: 4em;
    padding-bottom: 10rem;
  }

  .albert-text-padding{
    padding-top: 14rem;
    padding-left: 4em;
    padding-bottom: 10rem;
  }

  .psychiatry-text-padding{
    padding-top: 10rem;
    padding-left: 7em;
    padding-bottom: 10rem;
  }

  .about-text-padding1{
    padding-top: 11rem;
    padding-left: 10em;
    padding-bottom: 7rem;
  }

  .about-text-padding2{
    padding-top: 3rem;
    padding-left: 1rem;
    padding-bottom: 4rem;
  }

  .well-visit-text-padding{
    padding-top: 12rem;
    padding-left: 7em;
    padding-bottom: 10rem;
  }

  .financial-text-padding{
    padding-top: 10rem;
    padding-left: 50rem;
    padding-bottom: 10rem;
  }


  .about-p-img{
    padding-top: 3rem;
    padding-left: 2rem;
  }

  .about-p-img1{
    padding-top: 4rem;
    padding-left: 1rem;
  }

  .about-t-padding{
    padding: 6rem;
  }

  .about-img-text-padding{
    padding-top: 3rem;
    padding-left: 6rem;
    /* padding-bottom: 11rem; */
  }

  .privacy-text-padding{
    padding-top: 12rem;
    padding-left: 7em;
    padding-bottom: 12rem;
  }

  .about-banner-max-width{
    max-width: 485px;
  }

  /* .provider-max-width{
    max-width: 200px;
  } */

  .display-block{
    display: flex;
    justify-content: center;
  }

  .about-padding{
    padding: 80px;
  }

  .about-padding1{
    padding: 60px;
  }

  .about-margin{
    margin-left: 12rem;
    margin-right: 12rem
}  

.about-padding2{
    padding: 60px 60px 60px 60px;
  }  

  .marginX{
    margin: 0px 20px 0px 10px;
  }

  .border-style{
    border-width: 0px 2px 0px 2px;   
  }

  .marginAll{
    margin: 4rem;
  }

  .contact-table-margin{
    margin-left: 5rem;
    padding-left: 4.5rem;
    border-width: 0px 0px 0px 1px;
  }

  .height-display-banner{
    height: 100%;
  }

  .height-display-banner-urgent{
    height: 100%;
  }

  .hr-style{
    background-color: var(--secondary-ss-color);
    color: var(--secondary-ss-color);
    height: 3px;
    border: 1px solid;
    opacity: 1;
  }

  .p-top-bottom2rem{
    padding-top: 4rem;
    padding-bottom: 4rem;
  }

  .p-top-bottom2rem3{
    padding-top: 3rem;
    padding-bottom: 3rem;
  }

  .p-top-bottom2rem4{
    padding-top: 4rem;
    padding-bottom: 4rem;
  }

  .p-top-bottom2rem1{
    padding-top: 5rem;
    padding-bottom: 6rem;
  }

  .p-top-bottom2rem2{
    padding-top: 5rem;
    padding-bottom: 4rem;
  }

  .p-tb{
    padding: 60px 80px 60px 60px;
  }

  .p-tb1{
    padding: 2.5rem;
  }
  
  .img-app{
    width :350px;
  }

  .imgs-caring-hands{
    width: 80%;
  }

  .img-w1{
    width :200px;
  }


  .img-w2{
    width :180px;
  }

  .img-w3{
    width :130px;
  }

  .img-w4{
    width :180px;
  }

  .services-margin-top{
    margin-top: 4rem;
  }

  .img-w5{
    width: 90%;
  }

  .img-w6{
    width: 80%;
  }

  .img-w7{
    width: 55%;
  }

  .img-w8{
    width: 75%;
  }

  .img-w9{
    width: 24%;
  }

  .img-w10{
    width: 35%;
  }

  .img-w11{
    width: 89%;
  }

  .img-w12{
    width: 47%;
  }

  .img-w13{
    width: 60%;
  }

  .img-w14{
    width: 200px;
    height: 60px;
  }

  .bottom-border1{
    border-bottom: 1px solid lightgray;
 }

 .family-padding{
    padding: 35px 50px 34px 50px;
 }

 .family-padding1{
    padding: 55px 60px 55px 60px;
 }

 .urgent-padding{
    padding: 38px 40px 44px 40px;
 }

 .urgent-padding1{
    padding: 55px 60px 55px 60px;
 }

 .pediatric-padding{
    padding: 50px 60px 50px 50px;
 }

 .pediatric-padding1{
    padding: 45px 40px 45px 50px;
 }

 .faq-padding{
    padding: 60px 40px 60px 40px;
 }

 @keyframes animate-left {
    0%{
        left: -60%; top: 0px;
    }
    25%{
        left: 6rem; top: 0px;
    }
    40%{
        left: 0rem; top: 0px;
    }
    50%{
        left: 1rem; top: 0px;
    }
    60%{
        left: 0rem; top: 0px;
    }
    70%{
        left: 1rem; left: 0px;
    }
    80%{
        left: 0%; left: 0px;
    }
 }

 .animate-text{
    position: relative;
    animation: animate-left 2.5s ease;
 }


 @keyframes fadeIn{
    from {
        opacity: 0;
    }
    to{
        opacity: 1;
    }
 }

 .fade-text{
    animation: fadeIn 2s ease;
 }

 .p-caring-hands{
    padding: 40px 20px 60px;
    /* margin-bottom: 40px; */
 }

 .img6-margin{
    margin-left: 54px;
 }

 .m-width{
    max-width: 150px;
} 

.top-header-margin-left{
    margin-left: 5rem;
}

.accordion-button{
    background-color: #ededed !important;
    border: 1px solid #d3d3d3 !important;

}

.about-t-bg{
    background-position: bottom right !important;
    background-repeat: no-repeat !important;
    background-size: 15% auto !important;
  }

 
  .bgw{
    width: 55%;
}

 .bg-img-h{
    height: 85vh;
 }

 .mr-header{
    margin-right: 4.5rem;
 }

 .marginLeftServices{
    margin-left: -50px;
 }

 .hover-bs:hover{
    box-shadow: 9.899px 9.899px 30px 0 rgba(0,0,0,.1) !important;
 }

 .home-banner-1{
    background-repeat: no-repeat;
    background-position: left;
    background-size: cover;
    position: relative;
    background-image: url(../images/home/Home-Slider-pic-1-v1.webp);
 }

 .home-banner-2{
    background-repeat: no-repeat;
    background-position: left;
    background-size: cover;
    position: relative;
    background-image: url(../images/home/Home-Slider-pic-2-v1.webp);
 }

 .carousel-control-prev{
    width: 9% !important;
 }

 .carousel-control-next{
    width: 9% !important;
 }